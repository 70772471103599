import { ContactInput } from "./ContactFormulier";
import FieldError from "./FieldError";

const emailRegexp = /\S+@\S+\.\S+/;

const validateContactInput = ({
  name,
  emailAddress,
}: ContactInput): FieldError[] => {
  const errors: FieldError[] = [];

  if (!name) {
    errors.push({ field: "name", message: "Naam kan niet leeg zijn" });
  }

  if (!emailAddress) {
    errors.push({
      field: "emailAddress",
      message: "Email kan niet leeg zijn",
    });
  } else if (!emailAddress.match(emailRegexp)) {
    errors.push({ field: "emailAddress", message: "Email is niet geldig" });
  }

  return errors;
};

export default validateContactInput;
