import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import { Input, Label } from "reactstrap";
import Box from "./Box";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label: string;
};

const InputField: React.FC<Props> = ({ size: _, ...props }) => {
  const [field, { error }] = useField(props);

  return (
    <Box mb="4">
      <Label htmlFor="">{props.label}</Label>
      <Input
        {...(field as any)}
        {...props}
        type={props.type || "text"}
        id={props.name}
      />
      <div style={{ color: "#e85656" }}>{error}</div>
    </Box>
  );
};

export default InputField;
