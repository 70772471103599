import { Formik, Form } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Box from "../general/Box";
import InputField from "../general/InputField";
import { H2, P } from "../general/Typography";
import Button from "../general/Button";
import validateContactInput from "./validateContactInput";
import toErrorMap from "./toErrorMap";
import useQueryParams from "../../hooks/useQueryParams";

interface Props {}

export type ContactInput = {
  name: string;
  phoneNumber: string;
  emailAddress: string;
  message: string;
};

const emptyInput: ContactInput = {
  name: "",
  phoneNumber: "",
  emailAddress: "",
  message: "",
};

const ContactFormulier: React.FC<Props> = () => {
  const params = useQueryParams();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [initialInput, setInitialInput] = useState(emptyInput);

  const handleSubmit = async (values: any, { setErrors }: any) => {
    const errors = validateContactInput(values);

    if (errors.length) return setErrors(toErrorMap(errors));

    const formData = new FormData();

    Object.keys(values).forEach(key => {
      formData.set(key, values[key]);
    });

    const data = await fetch("https://formspree.io/xzbkaebb", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    }).then(res => res.json());

    if (data.ok) {
      setSuccess(true);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (params.get("message")) {
      let message = params.get("message");

      message = message.replaceAll("<br>", "\n");

      setInitialInput({ ...initialInput, message });

      document.getElementById("name")!.focus();
      document
        .getElementById("form-title")
        .scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const hasProeflesNote = params.get("message")?.match(/proefles/);

  return (
    <Box>
      <H2 fontSize="medium" id="form-title" className="mb-3">
        Stuur ons een bericht:
      </H2>
      <Formik
        enableReinitialize={true}
        initialValues={initialInput}
        onSubmit={handleSubmit}
      >
        {() => (
          <>
            {success ? (
              <Box my="3">
                Bedankt voor je bericht! We nemen zo snel mogelijk contact met
                je op!
              </Box>
            ) : null}

            {!success ? (
              <Form>
                <InputField name="name" label="Naam" />
                <InputField name="phoneNumber" label="Telefoonnummer" />
                <InputField name="emailAddress" label="Emailadres" />
                <InputField
                  name="message"
                  label="Bericht"
                  type="textarea"
                  style={{ height: 150, marginBottom: 0 }}
                />

                {hasProeflesNote && (
                  <Box mb="4">
                    <P cursive>
                      * Bij afname van een pakket is de proefles gratis
                    </P>
                  </Box>
                )}

                <Button type="submit">Verzend</Button>
              </Form>
            ) : null}

            {error ? (
              <Box mt="4">
                <P className="text-danger">
                  Er ging iets mis! Probeer het opnieuw of stuur een email naar{" "}
                  <a
                    className="text-danger"
                    style={{ textDecoration: "underline" }}
                    href="mailto:info@rijschoollamar.nl"
                  >
                    info@rijschoollamar.nl
                  </a>
                </P>
              </Box>
            ) : null}
          </>
        )}
      </Formik>
    </Box>
  );
};

export default ContactFormulier;
