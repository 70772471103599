import React from "react";
import { Col, Container, Row } from "reactstrap";
import ContactFormulier from "../components/contact/ContactFormulier";
import Box from "../components/general/Box";
import ContactList from "../components/general/ContactList";
import { H1, H2 } from "../components/general/Typography";
import Layout from "../components/layout/Layout";
import Head from "../components/general/Head";

interface Props {}

const contact: React.FC<Props> = () => {
  return (
    <Layout>
      <Head page="contact" />

      <Container>
        <Row>
          <Col xs="12" md="5">
            <Box my="4" my-md="5">
              <H1 color="blue" upcase>
                Contact
              </H1>
            </Box>
            <H2 fontSize="medium">Rijschool Lamar</H2>
            <ContactList variant="withAddress" />
          </Col>

          <Col xs="12" md="7">
            <Box mt="5" mb="5">
              <ContactFormulier />
            </Box>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default contact;
